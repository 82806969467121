<script setup>
import Icon from '@components/Icon.vue'
import Spinner from '@components/Spinner.vue'
import { ref, reactive, onMounted } from 'vue'
import { $leadCaptureOpen } from '../store'
import Cookies from 'js-cookie'
import api from '@helpers/api'
import { generateRecaptchaToken } from '@helpers/utils'

const tabVisible = ref(false)
const modalOpen = ref(false)
const submitted = ref(false)
const submitting = ref(false)
const errorMessage = ref('')
const form = reactive({
  list_id: 'WQh9NS',
  email: '',
  name: '',
})

onMounted(() => {
  if (!Cookies.get('lead_capture_closed')) {
    tabVisible.value = true

    document.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > window.innerHeight) {
        open()
      }
    })
  }
})

function alwaysOpen() {
  $leadCaptureOpen.set((new Date()).toString())
  modalOpen.value = true
}

function open() {
  if (!$leadCaptureOpen.get()) {
    alwaysOpen()
  }
}

function close() {
  tabVisible.value = false
  modalOpen.value = false
  setCookie()
}

function setCookie() {
  Cookies.set('lead_capture_closed', (new Date()), { expires: 365 })
}

async function subscribe() {
  submitted.value = false
  submitting.value = true

  form.captcha = await generateRecaptchaToken('subscribe')

  api
      .post(`/subscribe`, form)
      .then(({ data }) => data)
      .then((data) => {
        submitted.value = true
        setCookie()
      })
      .catch((error) => {
        errorMessage.value = error?.response?.data?.message || 'An unexpected error occurred.'
      })
      .finally(() => {
        submitting.value = false
      })
}
</script>

<template>
  <div class="lead">
    <div class="lead__tab" v-if="tabVisible">
      <button type="button" class="lead__button" @click="alwaysOpen">
        <span class="lead__text">GET MY $10</span>
        <a href="#" class="lead__close" @click.prevent="close" aria-label="Close">
          <svg viewBox="0 0 7.5 7.5"><line x1="0.5" y1="0.5" x2="7" y2="7" stroke="#000" stroke-width="1" stroke-linecap="butt"></line><line x1="7" y1="0.5" x2="0.5" y2="7" stroke="#000" stroke-width="1" stroke-linecap="butt"></line></svg>
        </a>
      </button>
    </div>

    <div class="lead__modal" v-if="modalOpen">
      <div class="lead__backdrop"></div>

      <div class="lead__inner">
        <div class="lead__wrapper">
          <a href="#" class="lead__close" @click.prevent="modalOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
              <circle cx="18" cy="18" r="18" fill="black" fill-opacity="0.5"/>
              <path d="M24.1885 12L11.9117 24.2768" stroke="white" stroke-width="2"/>
              <path d="M24.1885 24.1958L12.1556 12.1629" stroke="white" stroke-width="2"/>
            </svg>
          </a>

          <div class="lead__image">
            <img src="/images/girl-smiling.jpg" alt="Eating gummy" />
          </div>
          <form class="lead__content" @submit.prevent="subscribe">
            <template v-if="submitted">
              <span class="lead__title">Thanks for signing up!</span>
              <a href="/shop" class="lead__button">START BROWSING</a>
              <a href="/c/best-sellers" class="lead__button">SHOP BEST SELLERS</a>
            </template>
            <template v-else>
              <span class="lead__title">Save a bit while you're here</span>
              <span class="lead__description">Join our community to get $10 off</span>

              <input v-model="form.email" type="text" placeholder="Enter email address" class="lead__input">
              <input v-model="form.name" type="text" placeholder="Name" class="lead__input">

              <div v-if="errorMessage" class="error">
                <Icon type="error" />
                {{ errorMessage }}
              </div>

              <button type="submit" class="lead__button" :disabled="submitting">
                <Spinner color="white" v-if="submitting" />
                <span v-else>GET MY DISCOUNT</span>
              </button>
            </template>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.lead {
  .lead__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483646;

    .lead__backdrop {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7;
      background-color: rgb(0, 0, 0);
    }

    .lead__inner {
      position: absolute;
      top: 10%;
      left: 50%;
      width: 90%;
      max-width: 500px;
      transform: translateX(-50%);

      .lead__wrapper {
        position: relative;

        .lead__close {
          position: absolute;
          top: 12px;
          right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;

          svg {
            width: 36px;
            height: 36px;
          }
        }
      }

      .lead__image {
        flex: 1;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .lead__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--Dark-navy, #102B38);
        padding: 24px;
        position: relative;
        border-radius: 0 0 8px 8px;

        .lead__title {
          display: block;
          color: #fff;
          font-size: 34px;
          line-height: 38px;
          margin-bottom: 16px;
          font-family: Outfit, 'Outfit Fallback', Arial, serif;
          font-weight: 700;
          text-align: center;
        }

        .lead__description {
          display: block;
          color: #fff;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 32px;
          text-align: center;
          font-family: Outfit, 'Outfit Fallback', Arial, serif;

          @media screen and (min-width: 768px) {
            font-size: 20px;
          }
        }

        .lead__input {
          border-radius: 12px;
          font-size: 14px;
          border: 0;
          background: #fff;
          padding: 12px 20px;
          width: 100%;
          margin-bottom: 12px;
          font-family: Outfit, 'Outfit Fallback', Arial, serif;
          color: #8A8A8A;
        }

        .lead__button {
          background: #DB7E5A;
          border-radius: 12px;
          color: #fff;
          font-family: Outfit, 'Outfit Fallback', Arial, serif;
          border: 0;
          width: 100%;
          padding: 12px;
          font-weight: 600;
          cursor: pointer;
          display: block;
          text-align: center;
          text-decoration: none;
          margin-bottom: 12px;
        }
      }
    }
  }

  .lead__tab {
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 2147483645;

    .lead__button {
      background: #DB7E5A;
      border: 0 solid rgba(13, 152, 235, 0);
      border-radius: 0 10px 10px 0;
      color: #fff;
      font-family: Outfit, 'Outfit Fallback', Arial, serif;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 22px;
      padding: 20px 10px;
      cursor: pointer;

      .lead__text {
        writing-mode: vertical-rl;
      }

      .lead__close {
        margin-top: 14px;
        flex-shrink: 0;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;

        svg {
          width: 7px;
          height: 7px;
        }
      }
    }
  }
}
</style>
